import { useState } from "react";

import { Table, tableFromArrays } from "apache-arrow";
import {
  extendTheme,
  ChakraProvider,
  Container,
  Flex,
  Heading,
  VStack,
} from "@chakra-ui/react";

import "./App.css";
import StatTable from "./components/StatTable";
import WeekFilters from "./components/WeekFilters";
import YearFilters from "./components/YearFilters";
import RankHistoryChart from "./components/RankHistoryChart";

const theme = extendTheme({
  config: {
    useSystemColorMode: false,
  },
});

const teams: { [key: string]: string[] } = {
  "2024": [
    "Sultans of Pain",
    "TBD",
    "The Ballbreakers",
    "The Mule",
    "7 Grains of Pain",
    "Eeephus",
  ],
};

const statFormatter = ({
  value,
  column,
}: {
  value: string | number;
  column: { id: string };
}) => {
  if (column.id === "avg" || column.id === "obp" || column.id === "slg") {
    return (+value).toFixed(3).replace("0.", ".");
  } else {
    return String(value);
  }
};

const rankFormatter = ({
  value,
  column,
}: {
  value: string | number;
  column: { id: string };
}) => {
  return String(value);
};

const history: { [key: string]: Table[] } = {
    "2024": [
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([369, 362, 379, 367, 381, 354]),
      run: new Uint32Array([48, 52, 67, 72, 65, 58]),
      hit: new Uint32Array([96,  93,  93,  96, 105,  83]),
      hr: new Uint32Array([12, 16, 16, 28, 16, 11]),
      rbi: new Uint32Array([42, 44, 51, 82, 62, 50]),
      sb: new Uint32Array([5, 9, 3, 6, 7, 9]),
      bb: new Uint32Array([29, 42, 50, 43, 44, 37]),
      avg: new Float32Array([0.26 , 0.257, 0.245, 0.262, 0.276, 0.234]),
      obp: new Float32Array([0.318, 0.335, 0.333, 0.351, 0.359, 0.31 ]),
      slg: new Float32Array([0.401, 0.425, 0.42 , 0.556, 0.462, 0.393]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([598, 593, 631, 593, 619, 589]),
      run: new Uint32Array([81,  91,  97, 102, 111, 109]),
      hit: new Uint32Array([159, 163, 152, 162, 174, 151]),
      hr: new Uint32Array([19, 31, 22, 32, 28, 24]),
      rbi: new Uint32Array([69,  87,  77, 116, 113,  88]),
      sb: new Uint32Array([10, 13,  6, 12,  9, 22]),
      bb: new Uint32Array([55, 74, 79, 74, 65, 69]),
      avg: new Float32Array([0.266, 0.275, 0.241, 0.273, 0.281, 0.256]),
      obp: new Float32Array([0.33, 0.358, 0.328, 0.363, 0.356, 0.341]),
      slg: new Float32Array([0.413, 0.474, 0.399, 0.504, 0.491, 0.448]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([823, 818, 895, 807, 844, 816]),
      run: new Uint32Array([114, 126, 126, 131, 136, 141]),
      hit: new Uint32Array([212, 226, 225, 215, 218, 214]),
      hr: new Uint32Array([26, 41, 32, 37, 33, 28]),
      rbi: new Uint32Array([94, 116, 116, 142, 145, 114]),
      sb: new Uint32Array([22, 18,  9, 17, 16, 36]),
      bb: new Uint32Array([84, 104,  92,  98,  94,  96]),
      avg: new Float32Array([0.258, 0.276, 0.251, 0.266, 0.258, 0.262]),
      obp: new Float32Array([0.328, 0.36 , 0.323, 0.353, 0.339, 0.346]),
      slg: new Float32Array([0.4  , 0.478, 0.406, 0.478, 0.44 , 0.431]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([1057, 1052, 1143, 1036, 1077, 1049]),
      run: new Uint32Array([144, 164, 161, 163, 168, 175]),
      hit: new Uint32Array([266, 281, 305, 271, 279, 267]),
      hr: new Uint32Array([37, 48, 46, 42, 41, 35]),
      rbi: new Uint32Array([121, 138, 162, 161, 186, 135]),
      sb: new Uint32Array([27, 24, 12, 24, 25, 52]),
      bb: new Uint32Array([111, 131, 112, 122, 127, 122]),
      avg: new Float32Array([0.252, 0.267, 0.267, 0.262, 0.259, 0.255]),
      obp: new Float32Array([0.325, 0.35 , 0.335, 0.346, 0.343, 0.338]),
      slg: new Float32Array([0.404, 0.458, 0.444, 0.458, 0.438, 0.415]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([1277, 1295, 1376, 1258, 1307, 1283]),
      run: new Uint32Array([177, 209, 189, 194, 198, 198]),
      hit: new Uint32Array([322, 351, 366, 326, 322, 321]),
      hr: new Uint32Array([45, 56, 54, 50, 47, 41]),
      rbi: new Uint32Array([148, 176, 188, 195, 210, 161]),
      sb: new Uint32Array([31, 39, 13, 28, 32, 60]),
      bb: new Uint32Array([139, 149, 146, 149, 153, 140]),
      avg: new Float32Array([0.252, 0.271, 0.266, 0.259, 0.246, 0.25 ]),
      obp: new Float32Array([0.328, 0.35 , 0.34 , 0.345, 0.332, 0.331]),
      slg: new Float32Array([0.41 , 0.455, 0.443, 0.452, 0.422, 0.408]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([1512, 1522, 1612, 1464, 1543, 1489]),
      run: new Uint32Array([212, 235, 226, 215, 239, 236]),
      hit: new Uint32Array([382, 416, 430, 370, 384, 375]),
      hr: new Uint32Array([54, 60, 68, 56, 58, 52]),
      rbi: new Uint32Array([180, 208, 232, 220, 247, 189]),
      sb: new Uint32Array([36, 44, 20, 29, 38, 70]),
      bb: new Uint32Array([165, 169, 173, 164, 178, 167]),
      avg: new Float32Array([0.253, 0.273, 0.267, 0.253, 0.249, 0.252]),
      obp: new Float32Array([0.327, 0.351, 0.342, 0.334, 0.332, 0.334]),
      slg: new Float32Array([0.412, 0.447, 0.453, 0.439, 0.426, 0.418]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([1755, 1754, 1841, 1702, 1769, 1714]),
      run: new Uint32Array([240, 262, 262, 260, 268, 262]),
      hit: new Uint32Array([448, 475, 496, 428, 435, 422]),
      hr: new Uint32Array([63, 66, 82, 68, 64, 58]),
      rbi: new Uint32Array([212, 230, 273, 251, 277, 211]),
      sb: new Uint32Array([40, 45, 23, 33, 40, 77]),
      bb: new Uint32Array([184, 199, 197, 181, 197, 186]),
      avg: new Float32Array([0.255, 0.271, 0.269, 0.251, 0.246, 0.246]),
      obp: new Float32Array([0.328, 0.35 , 0.345, 0.329, 0.327, 0.326]),
      slg: new Float32Array([0.416, 0.442, 0.466, 0.438, 0.42 , 0.407]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([1999, 2007, 2079, 1945, 2018, 1962]),
      run: new Uint32Array([280, 292, 298, 285, 302, 290]),
      hit: new Uint32Array([501, 538, 557, 495, 495, 478]),
      hr: new Uint32Array([76, 73, 96, 74, 80, 65]),
      rbi: new Uint32Array([247, 262, 316, 275, 326, 236]),
      sb: new Uint32Array([45, 49, 23, 37, 45, 85]),
      bb: new Uint32Array([207, 213, 230, 207, 219, 208]),
      avg: new Float32Array([0.251, 0.268, 0.268, 0.254, 0.245, 0.244]),
      obp: new Float32Array([0.322, 0.344, 0.345, 0.333, 0.324, 0.321]),
      slg: new Float32Array([0.416, 0.434, 0.469, 0.438, 0.43 , 0.403]),
    }),
    tableFromArrays({
      team: teams["2024"],
      ab: new Uint32Array([2216, 2257, 2323, 2181, 2262, 2212]),
      run: new Uint32Array([304, 328, 333, 315, 336, 327]),
      hit: new Uint32Array([553, 611, 629, 560, 564, 537]),
      hr: new Uint32Array([82,  87, 111,  82,  87,  70]),
      rbi: new Uint32Array([268, 296, 358, 309, 362, 263]),
      sb: new Uint32Array([48, 53, 29, 41, 49, 94]),
      bb: new Uint32Array([233, 235, 261, 223, 237, 235]),
      avg: new Float32Array([0.25 , 0.271, 0.271, 0.257, 0.249, 0.243]),
      obp: new Float32Array([0.322, 0.345, 0.348, 0.332, 0.325, 0.32 ]),
      slg: new Float32Array([0.413, 0.446, 0.477, 0.437, 0.43 , 0.395]),
    }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([2443, 2489, 2552, 2412, 2485, 2462]),
      run: new Uint32Array([333, 366, 377, 354, 365, 359]),
      hit: new Uint32Array([599, 678, 702, 620, 618, 596]),
      hr: new Uint32Array([89,  92, 125,  92,  96,  83]),
      rbi: new Uint32Array([292, 316, 406, 341, 390, 293]),
      sb: new Uint32Array([51,  62,  35,  46,  57, 100]),
      bb: new Uint32Array([250, 258, 296, 253, 259, 253]),
      avg: new Float32Array([0.245, 0.272, 0.275, 0.257, 0.249, 0.242]),
      obp: new Float32Array([0.318, 0.347, 0.354, 0.335, 0.324, 0.318]),
      slg: new Float32Array([0.406, 0.443, 0.488, 0.436, 0.427, 0.4  ]),
    }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([2675, 2727, 2807, 2618, 2717, 2696]),
      run: new Uint32Array([361, 403, 404, 380, 396, 409]),
      hit: new Uint32Array([661, 743, 769, 677, 680, 660]),
      hr: new Uint32Array([101, 101, 135,  98, 107, 101]),
      rbi: new Uint32Array([326, 347, 440, 370, 419, 338]),
      sb: new Uint32Array([55,  72,  39,  51,  61, 109]),
      bb: new Uint32Array([272, 284, 314, 277, 275, 285]),
      avg: new Float32Array([0.247, 0.272, 0.274, 0.259, 0.25 , 0.245]),
      obp: new Float32Array([0.319, 0.347, 0.351, 0.337, 0.324, 0.321]),
      slg: new Float32Array([0.41 , 0.443, 0.484, 0.437, 0.43 , 0.412]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([2907, 2966, 3052, 2858, 2956, 2939]),
      run: new Uint32Array([400, 447, 448, 413, 430, 435]),
      hit: new Uint32Array([739, 815, 845, 740, 738, 718]),
      hr: new Uint32Array([113, 108, 151, 111, 118, 111]),
      rbi: new Uint32Array([373, 373, 487, 408, 450, 359]),
      sb: new Uint32Array([59,  74,  44,  53,  66, 116]),
      bb: new Uint32Array([305, 314, 336, 286, 290, 298]),
      avg: new Float32Array([0.254, 0.275, 0.277, 0.259, 0.25 , 0.244]),
      obp: new Float32Array([0.327, 0.35 , 0.352, 0.333, 0.322, 0.318]),
      slg: new Float32Array([0.425, 0.444, 0.49 , 0.44 , 0.429, 0.413]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([3132, 3198, 3297, 3094, 3184, 3187]),
      run: new Uint32Array([429, 496, 485, 455, 467, 473]),
      hit: new Uint32Array([788, 888, 925, 806, 802, 779]),
      hr: new Uint32Array([119, 120, 163, 127, 129, 122]),
      rbi: new Uint32Array([399, 420, 536, 443, 494, 392]),
      sb: new Uint32Array([62,  80,  48,  58,  71, 119]),
      bb: new Uint32Array([333, 344, 361, 305, 321, 319]),
      avg: new Float32Array([0.252, 0.278, 0.281, 0.261, 0.252, 0.244]),
      obp: new Float32Array([0.327, 0.353, 0.356, 0.334, 0.326, 0.317]),
      slg: new Float32Array([0.419, 0.45, 0.494, 0.447, 0.435, 0.416]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([3373, 3416, 3520, 3317, 3414, 3412]),
      run: new Uint32Array([473, 541, 523, 486, 505, 510]),
      hit: new Uint32Array([860, 952, 984, 873, 869, 842]),
      hr: new Uint32Array([133, 137, 175, 140, 141, 133]),
      rbi: new Uint32Array([443, 461, 576, 485, 531, 424]),
      sb: new Uint32Array([69,  83,  50,  65,  78, 128]),
      bb: new Uint32Array([354, 367, 381, 325, 338, 345]),
      avg: new Float32Array([0.255, 0.279, 0.28 , 0.263, 0.255, 0.247]),
      obp: new Float32Array([0.329, 0.354, 0.355, 0.336, 0.327, 0.32]),
      slg: new Float32Array([0.428, 0.458, 0.494, 0.453, 0.439, 0.42]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([3735, 3739, 3878, 3658, 3750, 3753]),
      run: new Uint32Array([522, 590, 564, 537, 550, 550]),
      hit: new Uint32Array([962, 1039, 1066,  966,  960,  941]),
      hr: new Uint32Array([147, 156, 187, 160, 159, 149]),
      rbi: new Uint32Array([502, 503, 614, 543, 576, 472]),
      sb: new Uint32Array([79,  89,  52,  73,  85, 139]),
      bb: new Uint32Array([381, 392, 430, 371, 367, 388]),
      avg: new Float32Array([0.258, 0.278, 0.275, 0.264, 0.256, 0.251]),
      obp: new Float32Array([0.33 , 0.352, 0.352, 0.339, 0.327, 0.325]),
      slg: new Float32Array([0.431, 0.462, 0.483, 0.456, 0.442, 0.428]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([3952, 3959, 4115, 3892, 3985, 3999]),
      run: new Uint32Array([547, 626, 594, 574, 584, 592]),
      hit: new Uint32Array([1013, 1097, 1125, 1027, 1017, 1009]),
      hr: new Uint32Array([156, 170, 195, 178, 170, 162]),
      rbi: new Uint32Array([531, 540, 642, 589, 606, 505]),
      sb: new Uint32Array([87,  93,  53,  78,  92, 151]),
      bb: new Uint32Array([404, 421, 459, 391, 388, 411]),
      avg: new Float32Array([0.256, 0.277, 0.273, 0.264, 0.255, 0.252]),
      obp: new Float32Array([0.329, 0.352, 0.351, 0.338, 0.326, 0.326]),
      slg: new Float32Array([0.431, 0.464, 0.48 , 0.461, 0.442, 0.434]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([4165, 4176, 4350, 4141, 4205, 4236]),
      run: new Uint32Array([578, 669, 634, 620, 625, 633]),
      hit: new Uint32Array([1066, 1160, 1195, 1094, 1075, 1076]),
      hr: new Uint32Array([165, 181, 207, 191, 183, 177]),
      rbi: new Uint32Array([557, 567, 676, 627, 647, 551]),
      sb: new Uint32Array([93,  94,  54,  82,  97, 159]),
      bb: new Uint32Array([425, 462, 487, 408, 404, 439]),
      avg: new Float32Array([0.256, 0.278, 0.275, 0.264, 0.256, 0.254]),
      obp: new Float32Array([0.329, 0.355, 0.352, 0.336, 0.325, 0.328]),
      slg: new Float32Array([0.433, 0.468, 0.481, 0.463, 0.445, 0.44 ]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([4404, 4458, 4647, 4385, 4454, 4501]),
      run: new Uint32Array([620, 711, 675, 656, 669, 675]),
      hit: new Uint32Array([1133, 1237, 1283, 1154, 1146, 1142]),
      hr: new Uint32Array([183, 197, 224, 202, 192, 194]),
      rbi: new Uint32Array([606, 620, 717, 658, 689, 603]),
      sb: new Uint32Array([95,  96,  59,  88, 104, 165]),
      bb: new Uint32Array([452, 493, 520, 433, 425, 464]),
      avg: new Float32Array([0.257, 0.277, 0.276, 0.263, 0.257, 0.254]),
      obp: new Float32Array([0.331, 0.355, 0.353, 0.336, 0.327, 0.327]),
      slg: new Float32Array([0.44 , 0.47 , 0.483, 0.461, 0.447, 0.445]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([4652, 4692, 4868, 4609, 4679, 4740]),
      run: new Uint32Array([658, 744, 704, 690, 703, 702]),
      hit: new Uint32Array([1198, 1292, 1344, 1207, 1206, 1194]),
      hr: new Uint32Array([196, 211, 233, 213, 200, 204]),
      rbi: new Uint32Array([638, 654, 746, 687, 712, 627]),
      sb: new Uint32Array([101, 102,  64,  90, 111, 168]),
      bb: new Uint32Array([471, 519, 550, 455, 450, 475]),
      avg: new Float32Array([0.258, 0.275, 0.276, 0.262, 0.258, 0.252]),
      obp: new Float32Array([0.33 , 0.352, 0.354, 0.334, 0.328, 0.324]),
      slg: new Float32Array([0.441, 0.469, 0.482, 0.459, 0.446, 0.443]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([4887, 4927, 5112, 4840, 4912, 4968]),
      run: new Uint32Array([699, 778, 738, 713, 730, 733]),
      hit: new Uint32Array([1255, 1347, 1402, 1259, 1255, 1251]),
      hr: new Uint32Array([207, 220, 246, 222, 209, 207]),
      rbi: new Uint32Array([667, 690, 790, 723, 735, 655]),
      sb: new Uint32Array([108, 108,  69,  97, 118, 173]),
      bb: new Uint32Array([495, 543, 570, 484, 467, 502]),
      avg: new Float32Array([0.257, 0.273, 0.274, 0.26 , 0.255, 0.252]),
      obp: new Float32Array([0.329, 0.351, 0.352, 0.333, 0.326, 0.325]),
      slg: new Float32Array([0.441, 0.466, 0.481, 0.457, 0.442, 0.44 ]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([5117, 5166, 5371, 5088, 5163, 5236]),
      run: new Uint32Array([739, 807, 765, 748, 771, 784]),
      hit: new Uint32Array([1315, 1401, 1469, 1332, 1318, 1327]),
      hr: new Uint32Array([222, 227, 256, 234, 217, 225]),
      rbi: new Uint32Array([705, 715, 822, 761, 760, 698]),
      sb: new Uint32Array([116, 113,  75, 104, 126, 186]),
      bb: new Uint32Array([510, 563, 598, 510, 497, 526]),
      avg: new Float32Array([0.257, 0.271, 0.274, 0.262, 0.255, 0.253]),
      obp: new Float32Array([0.329, 0.348, 0.351, 0.335, 0.326, 0.326]),
      slg: new Float32Array([0.443, 0.461, 0.478, 0.459, 0.441, 0.445]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([5353, 5400, 5623, 5308, 5384, 5472]),
      run: new Uint32Array([772, 847, 795, 777, 795, 822]),
      hit: new Uint32Array([1366, 1466, 1533, 1385, 1371, 1381]),
      hr: new Uint32Array([233, 239, 265, 240, 223, 234]),
      rbi: new Uint32Array([743, 755, 857, 791, 782, 725]),
      sb: new Uint32Array([130, 116,  79, 108, 131, 197]),
      bb: new Uint32Array([536, 589, 619, 526, 516, 554]),
      avg: new Float32Array([0.255, 0.271, 0.273, 0.261, 0.255, 0.252]),
      obp: new Float32Array([0.327, 0.348, 0.349, 0.334, 0.325, 0.325]),
      slg: new Float32Array([0.44 , 0.463, 0.476, 0.456, 0.438, 0.444]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([5568, 5630, 5855, 5547, 5586, 5703]),
      run: new Uint32Array([800, 888, 826, 810, 823, 860]),
      hit: new Uint32Array([1419, 1523, 1592, 1444, 1432, 1439]),
      hr: new Uint32Array([240, 254, 280, 252, 227, 242]),
      rbi: new Uint32Array([773, 801, 893, 828, 805, 761]),
      sb: new Uint32Array([135, 119,  83, 112, 137, 203]),
      bb: new Uint32Array([550, 614, 653, 553, 543, 585]),
      avg: new Float32Array([0.255, 0.271, 0.272, 0.26 , 0.256, 0.252]),
      obp: new Float32Array([0.326, 0.347, 0.35 , 0.333, 0.327, 0.326]),
      slg: new Float32Array([0.439, 0.463, 0.478, 0.455, 0.438, 0.443]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([5812, 5882, 6105, 5776, 5812, 5949]),
      run: new Uint32Array([840, 926, 855, 842, 856, 905]),
      hit: new Uint32Array([1483, 1595, 1656, 1499, 1504, 1514]),
      hr: new Uint32Array([253, 267, 288, 262, 233, 257]),
      rbi: new Uint32Array([820, 835, 926, 860, 828, 808]),
      sb: new Uint32Array([151, 128,  89, 117, 142, 212]),
      bb: new Uint32Array([577, 635, 685, 578, 563, 606]),
      avg: new Float32Array([0.255, 0.271, 0.271, 0.26 , 0.259, 0.254]),
      obp: new Float32Array([0.327, 0.347, 0.35 , 0.333, 0.329, 0.327]),
      slg: new Float32Array([0.441, 0.465, 0.474, 0.453, 0.439, 0.447]),
  }),
  tableFromArrays({
    team: teams["2024"],
      ab: new Uint32Array([6007, 6109, 6354, 5969, 5998, 6164]),
      run: new Uint32Array([873, 964, 898, 854, 876, 936]),
      hit: new Uint32Array([1539, 1655, 1728, 1541, 1547, 1569]),
      hr: new Uint32Array([262, 279, 300, 265, 238, 263]),
      rbi: new Uint32Array([843, 870, 960, 880, 852, 828]),
      sb: new Uint32Array([164, 132,  90, 119, 146, 221]),
      bb: new Uint32Array([598, 659, 709, 595, 577, 618]),
      avg: new Float32Array([0.256, 0.271, 0.272, 0.258, 0.258, 0.255]),
      obp: new Float32Array([0.328, 0.347, 0.35 , 0.331, 0.328, 0.326]),
      slg: new Float32Array([0.441, 0.466, 0.475, 0.448, 0.438, 0.445]),
    }),
  ]
};

const computeRankings = (history: Table[], yearKey: number) => {
  const numTeams = history[0].numRows;
  const rankDesc = (arr: number[]) => {
    const sorted = [...arr].sort((a, b) => b - a);
    return arr.map((x: number) => numTeams - sorted.indexOf(x));
  };

  return history.map((tbl) => {
    // all of these variables are actually rankings
    const abs = rankDesc(tbl.getChild("ab")!.toArray());
    const runs = rankDesc(tbl.getChild("run")!.toArray());
    const hits = rankDesc(tbl.getChild("hit")!.toArray());
    const hr = rankDesc(tbl.getChild("hr")!.toArray());
    const rbi = rankDesc(tbl.getChild("rbi")!.toArray());
    const sb = rankDesc(tbl.getChild("sb")!.toArray());
    const bb = rankDesc(tbl.getChild("bb")!.toArray());
    const avg = rankDesc(tbl.getChild("avg")!.toArray());
    const obp = rankDesc(tbl.getChild("obp")!.toArray());
    const slg = rankDesc(tbl.getChild("slg")!.toArray());

    const totals = [];
    for (let i = 0; i < tbl.numRows; i++) {
      const total =
        abs[i] +
        runs[i] +
        hits[i] +
        hr[i] +
        rbi[i] +
        sb[i] +
        bb[i] +
        avg[i] +
        obp[i] +
        slg[i];
      totals.push(total);
    }
    return tableFromArrays({
      team: teams[String(yearKey)],
      ab: abs,
      run: runs,
      hit: hits,
      hr: hr,
      rbi: rbi,
      sb: sb,
      bb: bb,
      avg: avg,
      slg: slg,
      obp: obp,
      total: totals,
    });
  });
};

function App() {
  const defaultYear = 2024;
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const yearValues = history[String(selectedYear)];
  const defaultWeek = yearValues.length;
  const [selectedWeek, setSelectedWeek] = useState(defaultWeek);
  console.log("currentWeek is " + selectedWeek);

  const handleYearChange = (year: number) => {
    setSelectedYear(year);
    // for now always reset to week 1; can add logic in the future
    // to retain selection across years but this is simpler
    setSelectedWeek(1);
  };

  const data = yearValues[selectedWeek - 1];
  const rankHistory = computeRankings(yearValues, selectedYear);
  const selectedRankings = rankHistory[selectedWeek - 1];

  return (
    <ChakraProvider theme={theme}>
      <Container maxWidth="container.xl" padding={0}>
        <Heading>Improved SoCal Math Machine</Heading>
        <YearFilters
          years={Object.keys(history).map(Number)}
          setYear={handleYearChange}
          selectedYear={selectedYear}
        />
        <RankHistoryChart rankHistory={rankHistory} />
        <Flex h="100v" direction={{ base: "column", md: "row" }}>
          <VStack w="full" h="full" spacing={10} alignItems="flex-start">
            <WeekFilters
              weeks={Array.from({ length: defaultWeek }, (v, i) => i + 1)}
              setWeek={setSelectedWeek}
              selectedWeek={selectedWeek}
            />
          </VStack>
        </Flex>
        <Flex h="100h" direction={{ base: "column", md: "row" }}>
          <VStack w="full" h="full" spacing={10} alignItems="flex-start">
            <StatTable
              data={data}
              header="Total Stats"
              formatter={statFormatter}
            />
          </VStack>
          <VStack w="full" h="full" spacing={10} alignItems="flex-start">
            <StatTable
              data={selectedRankings}
              header="Rankings"
              formatter={rankFormatter}
            />
          </VStack>
        </Flex>
      </Container>
    </ChakraProvider>
  );
}

export default App;
